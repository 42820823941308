document.addEventListener('DOMContentLoaded', function () {
  var swiper = new Swiper('.js-swiper', {
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  });
  var news_swiper = new Swiper('.js-news', {
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
    },
  });
  var sec03slider = new Swiper('.js-sec03slider', {
    slidesPerView: 'auto',
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
})